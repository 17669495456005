import React from 'react'
import styled from 'styled-components'

const ButtonContainer = styled.button`
    background: #F5F5F5;
    border-radius: 25px;
    height: 52px;
    padding-inline: 22px;
    border: 1px solid transparent;
    letter-spacing: -0.02em;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    white-space: nowrap;
    @media (max-width: 575.98px) {
        font-size: 16px;
        padding-inline: 18px;
    }
`

function Button({ children, fontSize, fontWeight, width, background, color, onClick, height }) {
    return (
        <ButtonContainer onClick={onClick}
            style={{
                fontSize: fontSize,
                fontWeight: fontWeight,
                width: width,
                background: background,
                color: color,
                height: height
            }}>
            {children}
        </ButtonContainer>
    )
}

export default Button