import React from 'react'
// import Title from '../../components/Title'
import CompanySlider from '../../components/CompanySlider'
import styled from 'styled-components'
import Paragraph from '../../components/Paragraph'


const TextContainer = styled.div`
    width: 100%;
    max-width: 500px;
    margin: auto;
`

function AboutSecondSection() {
    return (
        <div className='sectionBreak'>

            <CompanySlider />
            <TextContainer className="sectionBreak">
                <Paragraph fontWeight={900}>
                    <span className='fs-4'>

                        Philosophie des Unternehmens:
                    </span>
                </Paragraph>
                <Paragraph >
                    Unsere Philosophie bei Swissma GmbH basiert auf der Überzeugung, dass Technologie die Arbeitswelt effizienter und menschenfreundlicher gestalten kann. Indem wir eng mit unseren Kunden und deren Mitarbeitenden zusammenarbeiten, entwickeln wir Nutzungsoptimierte Funktionen, die Ressourcen sparen und Arbeitsprozesse verbessern. Unser Ziel ist es nicht, Personal abzubauen, sondern Menschen die Möglichkeit zu geben, von überall auf der Welt auf ihre Arbeit zuzugreifen und ihre Zeit für kreative und wertschöpfende Aufgaben zu nutzen.
                </Paragraph>
                <Paragraph>
                    Wir glauben, dass Mitarbeitende, die sich auf innovative und kollaborative Tätigkeiten konzentrieren können, das volle Potenzial ihrer Fähigkeiten entfalten und somit einen entscheidenden Beitrag zum Erfolg eines Unternehmens leisten. Durch die Automatisierung von repetitiven und automatisierbaren Aufgaben ermöglichen wir es unseren Kunden, sich auf das Wesentliche zu konzentrieren und ihre Geschäftsziele nachhaltig zu erreichen.
                </Paragraph>
                <Paragraph>
                    Bei Swissma GmbH setzen wir uns dafür ein, die Arbeitswelt von morgen aktiv mitzugestalten und unseren Kunden dabei zu helfen, sich in einer zunehmend digitalisierten Welt erfolgreich zu positionieren.
                </Paragraph>
            </TextContainer>
        </div>
    )
}

export default AboutSecondSection