import React from 'react'
import styled from 'styled-components'
import Button from './Button'

const SwiperCard = styled.div`
    background: #19191A;
    border-radius: 36px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    padding: 10px;
    color: #fff;
    flex: 1;
`
const SlideImg = styled.img`
    border-radius: 30px;
`
const Label = styled.div`
    font-weight: 900;
    font-size: 20px;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 10px;
    @media (max-width: 1399.98px) {
        font-size: 18px;
        padding: 3px 0px;
    }

    
    @media (max-width: 575.98px) {
        font-size: 16px;
        padding: 3px 0px;

    }
`
const SlideTitle = styled.div`
    font-weight: 400;
    font-size: 25px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 0rem;
    line-height: 30px;
`
const ContentWrapper = styled.div`
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 575.98px) {
        font-size: 16px;
        padding: 0.5rem;
    }
`


export default function StaffCards({ image, label, title, subtitle, buttonTitle, onClick, instancesCount, ImageLoaded, noImage }) {

    return (
        <SwiperCard>


            <SlideImg src={image} alt="" onLoad={() => { ImageLoaded() }} style={{ display: noImage && 'none' }} />

            <ContentWrapper>
                <div className='text-center'>
                    <SlideTitle>
                        {title}
                    </SlideTitle>
                    <Label>
                        {label}
                    </Label>
                    {subtitle && (
                        <div className='pb-4' style={{ whiteSpace: 'pre-line' }}>
                            {subtitle}
                        </div>
                    )}
                </div>

                {buttonTitle && (
                    <Button
                        onClick={onClick}
                        fontSize={'16px'}
                        fontWeight={500}
                        width={'100%'}
                        background={'#1966FF'}
                        color={'#fff'}
                    >
                        {buttonTitle}
                    </Button>
                )}

            </ContentWrapper>
        </SwiperCard>
    )
}