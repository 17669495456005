import React from 'react'
import styled from 'styled-components'
import Image from '../../assets/images/about/heroImage.png'
import { useWindowScrollPositions } from '../../hooks/useWindowScrollPositions'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'


const PhotoContainer = styled.div`
    background: url(${Image}) no-repeat;
    height: 800px;
    background-size: cover;
    background-position: center;
    border-radius: 21px;
    @media (max-width: 575.98px) {
        height: auto;
        aspect-ratio: 2/3;
        background-position-x: calc(50% - 20px);

    }
`

// const TextContainer = styled.div`
//     font-weight: 700;
//     font-size: 30px;
//     line-height: 135.4%;
//     text-align: center;
//     letter-spacing: -0.02em;
//     text-transform: uppercase;
//     color: #FFFFFF;
//     width: 65%;
//     margin-inline: auto;
//     padding-top: 10%;
//     padding-bottom: 2rem;
//     position: sticky;
//     top: 10%;
//     @media (max-width: 1399.98px) {

//     }

//     @media (max-width: 1199.98px) {
//         font-size: 26px;
//     }


//     @media (max-width: 991.98px) {
//         font-size: 20px;
//     }

//     @media (max-width: 767.98px) {
//         font-size: 18px;

//     }

//     @media (max-width: 575.98px) {
//         font-size: 16px;
//     }

// `
const FixedContainer = styled.div`
    position: fixed;
    z-index: -1;
    top: 12rem;
    right: 0px;
    width: 100%;
    padding-inline: 1.5rem;
    @media (max-width: 575.98px) {
        top: 10rem;

    }
`

const TextWrapper = styled.div`
    color: #fff;
    width: 80%;
    margin-inline: auto;
    @media (max-width: 575.98px) {
        width: 100%;
    }
`

function AboutFirstSection() {
    return (
        <div style={{ position: 'relative' }}>
            {
                useWindowScrollPositions().scrollY < 300 && (
                    <FixedContainer>
                        <Title>Über uns</Title>
                    </FixedContainer>
                )}

            <PhotoContainer>

            </PhotoContainer>
            <TextWrapper className="sectionBreak">
                <Paragraph>
                    Swissma GmbH ist ein innovatives Unternehmen, das sich auf die Entwicklung von benutzerfreundlichen und ansprechenden digitalen Lösungen für eine Vielzahl von Branchen spezialisiert hat.
                </Paragraph>
                <Paragraph>
                    Seit unserer Gründung haben wir uns darauf konzentriert, die Anforderungen und Ziele unserer Kunden zu verstehen und massgeschneiderte Lösungen für sie zu entwickeln.
                </Paragraph>
                <Paragraph>
                    Unser vielfältiges Angebot umfasst Webdesign, SaaS-Entwicklung, Unternehmens- und IT-Beratung, Online-Marketing, Projektunterstützung sowie Geschäftsprozessanalyse und -automatisierung. Zu unseren Hauptkunden zählen renommierte Unternehmen aus der Versicherungsbranche, dem Vertriebswesen, Banken und Bundesbehörden.
                </Paragraph>
            </TextWrapper>
        </div>
    )
}

export default AboutFirstSection