import React from 'react'
import Title from '../../components/Title'
import ServicesSlider from '../../components/ServicesSlider'

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

function ThirdSection() {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let ctx2 = gsap.context(() => {
            const tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".servicesTitle",
                    start: "-=80 40%",
                    end: " -5%",
                    pin: true,
                    scrub: 0.2,
                    markers: false,
                    toggleActions: "play reverse play reverse",
                }

            });

            tl2
                .to('.servicesTitle', { opacity: 1, duration: 1 }, -1)
                .to('.servicesTitle', { opacity: 0, duration: 1 }, 1)

        });
        return () => ctx2.revert();

    }, [])
    return (
        <>
            <div className='heightBoxWrapper'>
                <div className="servicesTitle hiddenTitle">
                    <Title>
                        Unsere Services
                    </Title>
                </div>
            </div>

            <ServicesSlider />
        </>
    )
}

export default ThirdSection