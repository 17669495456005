import React from 'react'

function Datenschutz() {
    return (
        <div className='container-lg px-4 sectionBreak'>
            <p>
                <b>Einleitung</b>
            </p>
            <p>
                Bei der Swissma GmbH legen wir großen Wert auf den Schutz und die Sicherheit der Informationen unserer Kunden und Website-Besucher. Diese Datenschutzerklärung beschreibt unsere Praktiken im Umgang mit der Sammlung, Nutzung und Sicherung Ihrer persönlichen Daten.
            </p><br />
            <p>
                <b>Datenerfassung und -nutzung</b>
            </p>
            <p>Wir sammeln persönliche Daten wie Namen, E-Mail-Adressen und Unternehmensdetails, wenn diese von unseren Besuchern freiwillig zur Verfügung gestellt werden, um unsere Dienstleistungen, Kundenunterstützung und das Benutzererlebnis zu verbessern. Diese Daten können verwendet werden, um unsere Angebote anzupassen oder Updates über unsere Dienstleistungen zu senden.
            </p>
            <br />
            <p>
                <b>Cookies und Tracking-Technologien</b>
            </p>
            <p>Unsere Website verwendet Cookies und ähnliche Technologien, um das Benutzererlebnis zu verbessern, Trends zu analysieren und die Website zu verwalten. Benutzer können die Verwendung von Cookies auf individueller Browser-Ebene steuern.
            </p><br />
            <p>
                <b>Datenweitergabe und Offenlegung</b>
            </p>
            <p>Swissma GmbH verkauft, handelt oder vermietet keine persönlichen Identifikationsinformationen an Dritte. Wir können generische aggregierte demografische Informationen, die nicht mit persönlichen Identifikationsinformationen verknüpft sind, mit unseren Geschäftspartnern, vertrauenswürdigen Affiliates und Werbetreibenden für die oben genannten Zwecke teilen.
            </p><br />
            <p>
                <b>Datensicherheit</b>
            </p>
            <p>Wir wenden angemessene Datenerfassungs-, Speicherungs- und Verarbeitungspraktiken sowie Sicherheitsmaßnahmen an, um den unbefugten Zugriff, die Änderung, Offenlegung oder Zerstörung Ihrer persönlichen Informationen zu verhindern.
            </p><br />
            <p>
                <b>Ihre Rechte</b>
            </p>
            <p>
                Sie haben das Recht, Zugang zu Ihren bei uns gespeicherten persönlichen Daten zu beantragen, diese zu korrigieren oder deren Löschung zu verlangen. Zusätzlich haben Sie das Recht, jederzeit Ihre Zustimmung zu widerrufen, vorbehaltlich gesetzlicher oder vertraglicher Einschränkungen und angemessener Vorankündigung.
            </p><br />
            <p>
                <b>Änderungen dieser Datenschutzrichtlinie</b>
            </p>
            <p>
                Swissma GmbH behält sich das Recht vor, diese Datenschutzrichtlinie jederzeit zu aktualisieren. Wir ermutigen Benutzer, diese Seite regelmäßig auf Änderungen zu überprüfen, um informiert zu bleiben, wie wir zum Schutz der von uns gesammelten persönlichen Informationen beitragen.
            </p><br />

        </div>
    )
}

export default Datenschutz