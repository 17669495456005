import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

// import Button from './Button'
import styled from "styled-components";

import Samir from '../assets/images/home/ProjectSupport.webp'
import Engineering from '../assets/images/home/Engineering.webp'
import Solution from '../assets/images/home/Solution.webp'
import Consulting from '../assets/images/home/Consulting.webp'
import Marketing from '../assets/images/home/Marketing.webp'

const SwiperCard = styled.div`
    border-radius: 30px;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 2/3;
    display: flex;
    flex-direction: column;
    justify-content:flex-end;
    align-items: center;
    padding: 1.5rem;
`
const SlideImg = styled.img`
    margin-bottom: 3rem;
    height: 400px;
    width: auto;
`
// const Label = styled.div`
//     background: rgba(247, 247, 242, 0.08);
//     border-radius: 9999px;
//     font-weight: 600;
//     font-size: 14px;
//     letter-spacing: 1px;
//     text-transform: uppercase;
//     color: #F5F5F5;
//     padding: 3px 10px;
//     margin-bottom: 1rem;
// `
const SlideTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    line-height: 30px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 2rem;
    @media (max-width: 575.98px) {
        font-size: 20px;
        margin-bottom: 1.5rem;

    }
`
// const ShowAll = styled.div`
//     margin-top: 1.5rem;
//     text-align: center;
//     color: #fff;
//     font-weight: 500;
//     cursor: pointer;
// `

export default function ServicesSlider() {
    return (
        <div >

            <Swiper
                slidesPerView={'auto'}
                spaceBetween={10}
                loop={false}
                className="mySwiper"
            >

                <SwiperSlide className="slideWidth">
                    <SwiperCard style={{ background: '#8B35F9' }}>
                        <SlideImg src={Consulting} alt="Consulting" />
                        {/* <Label>
                            Thursday, Oct 5, 2023
                        </Label> */}
                        <SlideTitle>
                            Consulting
                        </SlideTitle>
                        {/* <Button fontSize={'16px'} fontWeight={500}>
                            Lesen
                        </Button> */}
                    </SwiperCard>
                    {/* <ShowAll>
                        Alle Artikel
                    </ShowAll> */}
                </SwiperSlide>
                <SwiperSlide className="slideWidth">
                    <SwiperCard style={{ background: '#FDC93F' }}>
                        <SlideImg src={Marketing} alt="Marketing" />
                        {/* <Label>
                            Thursday, Oct 5, 2023
                        </Label> */}
                        <SlideTitle>
                            Marketing
                        </SlideTitle>
                        {/* <Button fontSize={'16px'} fontWeight={500}>
                            Lesen
                        </Button> */}

                    </SwiperCard>
                    {/* <ShowAll>
                        Alle Artikel
                    </ShowAll> */}
                </SwiperSlide>
                <SwiperSlide className="slideWidth">
                    <SwiperCard style={{ background: '#F97035' }}>
                        <SlideImg src={Solution} alt="Solution" />
                        {/* <Label>
                            Thursday, Oct 5, 2023
                        </Label> */}
                        <SlideTitle>
                            SaaS Lösungen
                        </SlideTitle>
                        {/* <Button fontSize={'16px'} fontWeight={500}>
                            Lesen
                        </Button> */}

                    </SwiperCard>
                    {/* <ShowAll>
                        Alle Artikel
                    </ShowAll> */}
                </SwiperSlide>
                <SwiperSlide className="slideWidth">
                    <SwiperCard style={{ background: '#50B8E7' }}>
                        <SlideImg src={Engineering} alt="Engineering" />
                        {/* <Label>
                            Thursday, Oct 5, 2023
                        </Label> */}
                        <SlideTitle>
                            Engineering
                        </SlideTitle>
                        {/* <Button fontSize={'16px'} fontWeight={500}>
                            Lesen
                        </Button> */}
                    </SwiperCard>
                    {/* <ShowAll>
                        Alle Artikel
                    </ShowAll> */}
                </SwiperSlide>
                <SwiperSlide className="slideWidth">
                    <SwiperCard style={{ background: '#8B35F9' }}>
                        <SlideImg src={Samir} alt="" className="img-fluid" />
                        {/* <Label>
                            Thursday, Oct 5, 2023
                        </Label> */}
                        <SlideTitle>
                            Projektunterstützung
                        </SlideTitle>
                        {/* <Button fontSize={'16px'} fontWeight={500}>
                            Lesen
                        </Button> */}
                    </SwiperCard>
                    {/* <ShowAll>
                        Alle Artikel
                    </ShowAll> */}
                </SwiperSlide>
                
            </Swiper>
        </div>
    );
}