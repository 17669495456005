// import NewsDetails from "../components/NewsDetails";
// import News from "../pages/News";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "../pages/About";
import ContactUs from "../pages/ContactUs";
import LandingPage from "../pages/LandingPage";
import Projekte from "../pages/Projekte";
import { useEffect, useState } from "react";
import Impressum from "../pages/Impressum";
import Datenschutz from "../pages/Datenschutz";


// const LandingPage = lazy(() => import('./pages/LandingPage'));
// const About = lazy(() => import('./pages/About'));
// const News = lazy(() => import('./pages/News'));
// const Projekte = lazy(() => import('./pages/Projekte'));
// const ContactUs = lazy(() => import('./pages/ContactUs'));
// const Navbar = lazy(() => import('./components/Navbar'));
// const Footer = lazy(() => import('./components/Footer'));
// const FullBgImage = lazy(() => import('./components/FullBgImage'));
// const NewsDetails = lazy(() => import('./components/NewsDetails'));

export default function Content() {
    const location = useLocation();
    const { pathname } = useLocation();

    const [displayLocation, setDisplayLocation] = useState(location);
    const [transitionStage, setTransistionStage] = useState("fadeIn");

    useEffect(() => {
        var splitLocation = pathname.split('/')
        var splitWords = splitLocation[1].split('-')

        var title = splitLocation[splitLocation.length - 1]

        if ((title === "")) {
            document.title = 'Swissma'
        }
        else if (splitWords[1]) {
            document.title = splitWords[0][0].toUpperCase() + splitWords[0].slice(1) + ' ' + splitWords[1]
        }
        else {
            document.title = title[0].toUpperCase() + title.substring(1)
        }

    }, [pathname])

    useEffect(() => {
        if (location !== displayLocation) setTransistionStage("fadeOut");
    }, [location, displayLocation]);


    return (
        <div
            className={`${transitionStage}`}
            onAnimationEnd={() => {
                if (transitionStage === "fadeOut") {
                    setTransistionStage("fadeIn");
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: 'instant',
                    });
                    setDisplayLocation(location);
                }
            }}
        >
            <Routes location={displayLocation}>
                <Route exact index path="/" element={<LandingPage />} />
                <Route exact path="/projekte" element={<Projekte />} />
                <Route exact path="/uber-uns" element={<About />} />
                {/* <Route exact path="/news" element={<News />} /> */}
                {/* <Route exact path="/news-1" element={<NewsDetails />} /> */}
                <Route exact path="/kontakt" element={<ContactUs />} />
                <Route exact path="/impressum" element={<Impressum />} />
                <Route exact path="/datenschutz" element={<Datenschutz />} />
            </Routes>
        </div>
    );
}
