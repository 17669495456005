// import { Suspense, lazy, useState } from 'react';
import React from 'react';
import './App.css';
import { BrowserRouter } from "react-router-dom";

import Navbar from './components/Navbar';
import Footer from './components/Footer';
import FullBgImage from './components/FullBgImage';

// import { ClipLoader } from 'react-spinners';
import Content from './routes/Content';




function App() {

  return (
    <div className="App">

      <BrowserRouter>

        {/* <Suspense
          fallback={
            <div className='loaderDiv'>
              <ClipLoader
                color="#dadada"
                size={80}
              />
            </div>
          }> */}
        <FullBgImage />
        <Navbar />
        <Content />
        <Footer />
        {/* </Suspense> */}

      </BrowserRouter>

    </div>
  );
}

export default App;


