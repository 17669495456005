import React from 'react'
import FirstSection from '../sections/Home/FirstSection'
import SecondSection from '../sections/Home/SecondSection'
import ThirdSection from '../sections/Home/ThirdSection'
import HomeNews from '../sections/Home/HomeNews'
import HomeContact from '../sections/Home/HomeContact'

function LandingPage() {
    return (
        <>
            <div className='container-lg px-4 firstSectionBreak'>
                <FirstSection />
            </div>

            <div style={{ overflow: 'hidden' }}>
                <div className='container-lg px-4 '>
                    <SecondSection />
                </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
                <div className='container-lg px-4'>
                    <ThirdSection />
                </div>
            </div>

            <div style={{ overflow: 'hidden' }}>
                <div className='container-lg px-4'>
                    <HomeNews />
                </div>
            </div>

            <div className='container-lg px-4'>
                <HomeContact />
            </div>

        </>
    )
}

export default LandingPage