import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'

import ContactImage from '../assets/images/home/contactRobot.webp'
import Input from './Input'
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'
// eslint-disable-next-line
import de from 'react-phone-number-input/locale/de'
import CheckBox from './CheckBox'
import Button from './Button'

const ContactWrapper = styled.div`
    background: rgba(0, 0, 0, 0.9);
    border-radius: 36px;
    padding: 10px;
    width: 85%;
    margin: auto;
    @media (max-width: 1199.98px) {
        width: 100%;
  
    }
    @media (max-width: 767.98px) {
        width: 80%;
    }
    @media (max-width: 575.98px) {
        width: 100%;
    }
`
const ImageWrapper = styled.img`
    border-radius: 30px 0px 0px 30px;
    max-width: 330px;
    width: 100%;
    @media (max-width: 1399.98px) {

    }

    @media (max-width: 767.98px) {
    }

`
const FormWrapper = styled.div`
    padding: 1rem;
    padding-left: calc(1rem + 10px);
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @media (max-width: 575.98px) {
        padding: 0.75rem;
    }
`
const FormTitle = styled.div`
    color: #F5F5F5;
    text-align: center;
    font-weight: 500;
    line-height: 21px;
    padding-bottom: 1.5rem;
`
const DataContainer = styled.div`
    color: #F5F5F5;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
`

function ContactForm() {
    const [telefonnumer, setTelefonnumer] = useState('');
    return (
        <ContactWrapper>
            <Row className='g-0'>
                <Col xs={12} md={6}>
                    <div className='d-flex justify-content-center align-items-center flex-column h-100 px-4 pt-4'>
                        <ImageWrapper src={ContactImage} alt='' />
                    </div>
                </Col>
                <Col xs={12} md={6}>
                    <FormWrapper>
                        <FormTitle className='pt-4 pt-md-4 pb-4 pb-sm-5'>
                            Füllen Sie das Kontaktformular aus für ein unverbindliches Erstgespräch.
                        </FormTitle>
                        <div className='pb-5'>

                            <Row className='g-3'>
                                <Col xs={12} sm={6}>
                                    <Input
                                        placeholder={'Vorname'}
                                        type={'text'}
                                    />
                                </Col>
                                <Col xs={12} sm={6}>
                                    <Input
                                        placeholder={'Nachname'}
                                        type={'text'}

                                    />


                                </Col>
                                <Col xs={12}>
                                    <Input
                                        placeholder={'Email'}
                                        type={'email'}

                                    />

                                </Col>
                                <Col>
                                    <div className="" style={{ position: 'relative' }}>
                                        <PhoneInput
                                            className={`phoneInput`}
                                            defaultCountry="CH"
                                            placeholder={'Telefonnumer'}
                                            international
                                            labels={de}
                                            withCountryCallingCode

                                            countrySelectProps={{ unicodeFlags: true }}
                                            value={telefonnumer}
                                            onChange={setTelefonnumer} />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        <div className='pb-4 pb-sm-5'>

                            <CheckBox
                                label={'Ich erkläre mich hiermit einverstanden, dass meine persönlichen Daten durch die Swissma weiter verarbeitet werden.'}
                            />
                            <div className='pt-3'>
                                <DataContainer>
                                    Wir werden Ihre Daten so lange aufbewahren, wie Sie es uns erlauben.
                                </DataContainer>
                            </div>
                        </div>

                        <Button
                            background={'#0E3C81'}
                            fontWeight={500}
                            width={'100%'}
                            fontSize={"16px"}
                            color={"#EBF3FF"}
                        >
                            Anfrage starten
                        </Button>
                    </FormWrapper>
                </Col>
            </Row>
        </ContactWrapper >
    )
}

export default ContactForm