import React from 'react'
import Button from './Button'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";

/* To do: Merge 2 categories swiper into a single reusable component */
function CategoriesSwiper({ active, setActive, data }) {

    return (
        <>
            <Swiper
                slidesPerView={'auto'}
                spaceBetween={5}
                centerInsufficientSlides={true}
                loop={false}
                className=" pb-5"
            >
                {data.categories.map((button) => {
                    return (
                        <SwiperSlide style={{ width: 'min-content' }} key={button.id}>
                            <Button
                                fontSize={'16px'}
                                background={active === button.id ? '#000' : 'transparent'}
                                color={active === button.id ? '#fff' : '#000'}
                                fontWeight={500}
                                onClick={() => setActive(button.id)}
                            >
                                {button.title}
                            </Button>
                        </SwiperSlide>
                    )
                })}

            </Swiper>
        </>
    )
}

export default CategoriesSwiper