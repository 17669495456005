import React from 'react'
import Subtitle from '../../components/Subtitle'
import styled from 'styled-components'
import Image from '../../assets/images/home/heroImg.webp'
import { Typewriter } from 'react-simple-typewriter'
import { useWindowScrollPositions } from '../../hooks/useWindowScrollPositions'

const TitleContainer = styled.div`
    font-weight: 700;
    line-height: 135.4%;
    font-size: 140px;
    color: #2E2E32;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    height: 160px;
    @media (max-width: 1399.98px) {
  
    }

    @media (max-width: 1199.98px) {
        font-size: 115px;
        height: 135px;

    }


    @media (max-width: 991.98px) {
        font-size: 90px;
        height: 120px;
    
    }

    @media (max-width: 767.98px) {
        font-size: 65px;
        height: 80px;

    }

    @media (max-width: 575.98px) {
        line-height: 1.5;
        font-size: 35px;
        height: 55px;

    }
    @media (max-width: 350.98px) {
        line-height: 1.5;
        font-size: 35px;
        height: 60px;
    }

`
const PhotoContainer = styled.div`
    background: url(${Image}) no-repeat;
    height: 800px;
    background-size: cover;
    background-position: center;
    border-radius: 21px;
    @media (max-width: 575.98px) {
        height: auto;
        aspect-ratio: 2/3;
    }
`

const TextContainer = styled.div`
    font-weight: 700;
    font-size: 30px;
    line-height: 135.4%;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    color: #FFFFFF;
    width: 65%;
    margin-inline: auto;
    padding-top: 10%;
    padding-bottom: 2rem;
    position: sticky;
    top: 10%;

    @media (max-width: 1399.98px) {
  
    }

    @media (max-width: 1199.98px) {
        font-size: 26px;
    }


    @media (max-width: 991.98px) {
        font-size: 20px;
    }

    @media (max-width: 767.98px) {
        font-size: 18px;

    }

    @media (max-width: 575.98px) {
        width: 90%;
        font-size: 20px;
    }

`
const FixedContainer = styled.div`
    position: fixed;
    z-index: -1;
    top: 12rem;
    right: 0px;
    width: 100%;
    padding-inline: 1.5rem;
    @media (max-width: 575.98px) {
        top: 10rem;

    }
`
function FirstSection() {
    return (
        <div style={{ position: 'relative' }}>
            {
                useWindowScrollPositions().scrollY < 300 && (
                    <FixedContainer>
                        <TitleContainer>
                            <Typewriter
                                loop
                                cursor={false}
                                typeSpeed={30}
                                deleteSpeed={30}
                                delaySpeed={1500}
                                words={['Digitale', 'Meisterwerke']}
                            />
                        </TitleContainer>
                        <div className='pb-4'>

                            <Subtitle>
                                Innovative IT- und Marketinglösungen für zukunftsweisende Organisationen
                            </Subtitle>
                        </div>
                    </FixedContainer>
                )}

            <PhotoContainer>
                <TextContainer>
                    Erfolg durch Präzision und Nutzerorientierung: Analyse, Konzeption und Umsetzung aus einer Hand!
                </TextContainer>
            </PhotoContainer>
        </div>
    )
}

export default FirstSection