import React from 'react'
import FirstSection from '../sections/Projekts/FirstSection'

function Projekte() {
    return (
        <div className='container-lg px-4 firstSectionBreak'>
            <FirstSection />
        </div>
    )
}

export default Projekte