import React from 'react'
import styled from 'styled-components'


const TextContainer = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    padding-bottom: 1.5rem;
    color: #2E2E32;
    :last-child {
        padding-bottom: 0rem;
    }
`

function Paragraph({ children, color, fontWeight }) {
    return (
        <TextContainer style={{ color: color, fontWeight: fontWeight }}>
            {children}
        </TextContainer>
    )
}

export default Paragraph