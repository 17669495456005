import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
// import NavbarSlider from './NavbarSlider'
import { Col, Row } from 'react-bootstrap'

const ContentWrapper = styled.div`
    padding-top: 0rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
`
const LinksWrapper = styled.div`
    padding-inline: 0.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    a {
        display: block;
        text-align: center;
        letter-spacing: -0.02em;
        text-transform: uppercase;
        color: #808080;
        font-size: 35px;
        text-decoration: none;
        font-weight: 700;
        transition: color 0.2s ease-in-out 
    }
    a:hover {
        color: #999999;
    }
`
const TextWrapper = styled.div`
    letter-spacing: -0.02em;
    text-transform: capitalize;
    color: #969696;  
`
function NavbarOpenContent() {

    return (
        <ContentWrapper>
            <LinksWrapper>
                <NavLink to='/' 
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#fff" : "",
                        };
                    }}
                >
                    Home
                </NavLink>
                <NavLink to='/projekte' 
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#fff" : "",
                        };
                    }}
                >
                    Projekte
                </NavLink>
                {/* <NavLink to='/news'
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#fff" : "",
                        };
                    }}
                >
                    News
                </NavLink> */}
                <NavLink to='/uber-uns'
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#fff" : "",
                        };
                    }}
                >
                    Über uns
                </NavLink>
                {/* <NavLink to='/karriere'
                    style={({ isActive }) => {
                        return {
                            color: isActive ? "#fff" : "",
                        };
                    }}
                >
                    Karriere
                </NavLink> */}
            </LinksWrapper>
            {/* <NavbarSlider /> */}
            <TextWrapper>
                <Row className='gx-5 justify-content-center'>
                    <Col xs={'auto'}>
                        <NavLink to='/kontakt' style={{ color: '#969696', textDecoration: 'none', }}>
                            Kontakt
                        </NavLink>
                    </Col>

                </Row>
                <div style={{ textAlign: 'center', fontSize: '14px' }} className='pt-2 pb-4'>
                    © 2023 SWISSMA All Rights Reserved.
                </div>
            </TextWrapper>
        </ContentWrapper>
    )
}

export default NavbarOpenContent