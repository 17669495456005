import React from 'react'
import ContactForm from '../components/ContactForm'
import Title from '../components/Title'
import Subtitle from '../components/Subtitle'
import styled from 'styled-components'


const FixedContainer = styled.div`
    position: absolute;
    z-index: -1;
    top: 12rem;
    right: 0px;
    width: 100%;
    padding-inline: 1.5rem;
    @media (max-width: 575.98px) {
        top: 10rem;

    }
`
function ContactUs() {
    return (
        <div className='container-lg px-4'>
            <FixedContainer>

                <Title>
                    KONTAKTIERE UNS
                </Title>
                <Subtitle>
                    Melde dich jetzt bei uns für ein unverbindliches Erstgespräch.
                </Subtitle>
            </FixedContainer>
            <div className='firstSectionBreak'>
                <ContactForm />
            </div>
        </div>
    )
}

export default ContactUs