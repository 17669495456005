import React from 'react'
import styled from 'styled-components'


const TitleContainer = styled.div`
    letter-spacing: 0.112px;
    color: #2E2E32;
    text-align:center;
    font-size: 25px;
    @media (max-width: 1399.98px) {
  
    }

    @media (max-width: 1199.98px) {
        font-size: 22px;
    }


    @media (max-width: 991.98px) {
        font-size: 20px;
    }

    @media (max-width: 767.98px) {
        font-size: 18px;

    }

    @media (max-width: 575.98px) {
        font-size: 16px;
    }
`

function Subtitle({ children }) {
    return (
        <TitleContainer>
            {children}
        </TitleContainer>
    )
}

export default Subtitle