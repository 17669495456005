import React from 'react'
import styled from 'styled-components'
import Button from './Button'


const SwiperCard = styled.div`
    background: #19191A;
    border-radius: 36px;
    width: 100%;
    max-width: 400px;
    aspect-ratio: 2/3;
    display: flex;
    flex-direction: column;
    justify-content:space-between;
    padding: 10px;
    color: #fff;
    height: 100%;
`
const SlideImg = styled.img`
    
`
const Label = styled.div`
    background: rgba(255, 255, 255, 0.15);
    width: min-content;
    border-radius: 9999px;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
    color: #fff;
    text-transform: uppercase;
    padding: 3px 10px;
    margin-bottom: 1rem;
    white-space: nowrap;
`
const SlideTitle = styled.div`
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 0.25px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 1.5rem;
    line-height: 30px;
    @media (max-width: 1399.98px) {
        font-size: 20px;
  
    }
`
const ContentWrapper = styled.div`
    padding: 1rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
`

function Cards({ image, label, title, subtitle, buttonTitle, maxWidth, aspectRatio, onClick, marginBottom, textAlign }) {
    return (
        <SwiperCard style={{ maxWidth: maxWidth, aspectRatio: aspectRatio }}>
            {image && (
                <SlideImg src={image} alt="" />
            )}
            <ContentWrapper>
                <div>
                    {label && (
                        <Label>
                            {label}
                        </Label>
                    )}
                    <SlideTitle style={{ marginBottom: marginBottom ? marginBottom : '', textAlign: textAlign ? textAlign : '' }}>
                        {title}
                    </SlideTitle>
                    {subtitle && (
                        <div className={`${buttonTitle && 'pb-4'}`} style={{ whiteSpace: 'pre-line' }}>
                            {subtitle}
                        </div>
                    )}
                </div>
                {buttonTitle && (
                    <Button onClick={onClick}
                        fontSize={'16px'}
                        fontWeight={500}
                        width={'100%'}
                        background={'#1966FF'}
                        color={'#fff'}
                    >
                        {buttonTitle}
                    </Button>
                )}
            </ContentWrapper>
        </SwiperCard>
    )
}

export default Cards