import React from 'react'

function Impressum() {
  return (
    <div className='container-lg px-4 sectionBreak'>
            <p>
                Gesetzlicher Vertreter: Samir Menasria, Geschäftsinhaber
            </p>
            <p>
                Firmenname: Swissma GmbH
            </p>
            <p>
                Adresse: Swissma GmbH, F&auml;llmisstrasse 12, 8832 Wilen b. Wollerau, Schweiz
            </p>
            <p>
                Kontaktinformationen: info@swissma.ch,
            </p>
            <p>
                Handelsregistereintrag: Eingetragener Firmenname: Swissma GmbH
            </p>
            <p>
                Unternehmensnummer (UID): CHE-215.063.772
            </p>
            <p>
                Mehrwertsteuernummer: CHE-215.063.772 MWST
            </p>
            <p>
                Dieser rechtliche Hinweis enthält wesentliche Informationen über die Swissma GmbH, den Betreiber der Website swissma.ch, im Einklang mit den gesetzlichen Anforderungen der Schweiz. Swissma GmbH ist darauf ausgerichtet, erstklassige Dienstleistungen einer Digitalagentur anzubieten, mit einem Fokus auf Innovation, Qualität und Kundenzufriedenheit.
            </p>
            <br /><br />
            <p><strong>Haftungsausschluss</strong></p>
            <p>
                Die Inhalte auf der Website der Swissma GmbH dienen nur zu allgemeinen Informationszwecken. Wir bemühen uns, die Informationen aktuell und korrekt zu halten, übernehmen jedoch keine Gewährleistung jeglicher Art hinsichtlich Vollständigkeit, Richtigkeit, Zuverlässigkeit, Eignung oder Verfügbarkeit bezüglich der Website oder der darauf gefundenen Informationen, Produkte, Dienstleistungen oder zugehörigen Grafiken. Jegliche Verwendung dieser Informationen erfolgt auf eigenes Risiko.
            </p>
            <br /><br />
            <p><strong>Haftungsausschluss für Links</strong></p>
            <p>
                Unsere Website enthält Links zu externen Websites, über die wir keine Kontrolle haben. Wir können für die Inhalte und Praktiken dieser externen Seiten keine Verantwortung oder Haftung übernehmen und raten Ihnen, deren Datenschutzrichtlinien zu überprüfen. Die Aufnahme eines Links impliziert nicht zwingend eine Empfehlung oder eine Bestätigung der dort ausgedrückten Ansichten.
            </p>
            <br /><br />
            <p><strong>Urheberrecht</strong></p>
            <p>
                Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem schweizerischen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Beiträge Dritter sind als solche gekennzeichnet.
            </p>
            <p>
                Diese Texte sollen als Grundlage für die rechtlichen Aspekte Ihrer Website dienen. Passen Sie sie entsprechend Ihrer spezifischen Situation an und ziehen Sie in Erwägung, einen Rechtsberater zu konsultieren, um die Einhaltung des schweizerischen Rechts und internationaler Standards sicherzustellen.
            </p>
        </div>
  )
}

export default Impressum