import React from 'react'
import styled from 'styled-components'

const InputWrapper = styled.input`
    border: 1px solid #8A8B8F;
    border-radius: 16px;
    background-color: transparent;
    padding: 1rem;
    color: #fff;
    font-weight: 500;
    width: 100%;

    ::placeholder {
        color: #8A8B8F;
        font-weight: 500;
    }
`

function Input({ placeholder, type }) {
    return (
        <InputWrapper
            type={type}
            placeholder={placeholder}
        />
    )
}

export default Input