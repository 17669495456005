import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { EffectCards, Pagination } from "swiper";
// import "swiper/css/effect-cards";
import 'swiper/css/pagination';
import styled from "styled-components";

import Insuhub from '../assets/images/home/stackInsuhub.png'
import Finanu from '../assets/images/home/stackFinanu.webp'
import Sana from '../assets/images/home/stackSana.webp'
import Insuplus from '../assets/images/home/stackInsuplus.webp'

import InsuhubMob from '../assets/images/home/stackInsuhubMob.png'
import FinanuMob from '../assets/images/home/stackFinanuMob.webp'
import SanaMob from '../assets/images/home/stackSanaMob.webp'
import InsuplusMob from '../assets/images/home/stackInsuplusMob.webp'

import useWindowDimensions from "../hooks/useWindowDimensions ";

const SwiperCard = styled.div`
    max-width: 70%;
    border-radius: 30px;
    aspect-ratio: 3/2;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media (max-width: 1399.98px) {
        max-width: 90%;
    }

    @media (max-width: 1199.98px) {
        max-width: 100%;
    
    }


    @media (max-width: 991.98px) {
    
    }

    @media (max-width: 767.98px) {
    }
    
    @media (max-width: 575.98px) {
        aspect-ratio: unset;
    }
`
export default function SliderComponent() {
    return (
        <div >
            {useWindowDimensions().width > 575.98
                ?
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    initialSlide={1}
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[EffectCards, Pagination]}
                    className="mySwiper pb-5 px-4 px-xl-0"
                >

                    <SwiperSlide>
                        <SwiperCard>
                            <img src={Insuhub} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>


                    <SwiperSlide>
                        <SwiperCard>
                            <img src={Finanu} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard>
                            <img src={Sana} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard>
                            <img src={Insuplus} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                </Swiper>

                :
                <Swiper
                    effect={"cards"}
                    grabCursor={true}
                    initialSlide={1}
                    loop={true}
                    pagination={{ clickable: true }}
                    modules={[EffectCards, Pagination]}
                    className="mySwiper pb-5 px-4 px-xl-0"
                >
                    <SwiperSlide>
                        <SwiperCard>
                            <img src={InsuhubMob} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>


                    <SwiperSlide>
                        <SwiperCard>
                            <img src={FinanuMob} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard>
                            <img src={SanaMob} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                    <SwiperSlide>
                        <SwiperCard>
                            <img src={InsuplusMob} alt="" className="img-fluid" />
                        </SwiperCard>
                    </SwiperSlide>
                </Swiper>
            }


        </div >
    );
}