import React from 'react'
import styled from 'styled-components'


const Label = styled.label`
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    color: #F5F5F5;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;

    input:checked ~ .checkmark:after {
        display: block;
    }

`
const CheckMark = styled.span`
    position: absolute;
    top: 4px;
    left: 0;
    height: 25px;
    width: 25px;
    border: 1px solid #8A8B8F;
    border-radius: 3px;
    :after {
        content: "";
        position: absolute;
        display: none;
    }
    :after {
        left: 8px;
    top: 2px;
    width: 7px;
    height: 15px;
        border: solid white;
        border-width: 0 2px 2px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }
`
function CheckBox({ label }) {
    return (
        <>
            <Label>{label}
                <input type="checkbox" hidden />
                <CheckMark className='checkmark'></CheckMark>
            </Label>

        </>
    )
}

export default CheckBox