import React from 'react'
import { Col, Row } from 'react-bootstrap'
import Cards from '../../components/Cards'
import { useWindowScrollPositions } from '../../hooks/useWindowScrollPositions'
import Title from '../../components/Title'
import styled from 'styled-components'

import ProjectData from '../../assets/data/projekte/Projects.json'

import { useEffect } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';


const FixedContainer = styled.div`
    position: fixed;
    z-index: -1;
    top: 12rem;
    right: 0px;
    width: 100%;
    padding-inline: 1.5rem;
    @media (max-width: 575.98px) {
        top: 10rem;

    }
`

function FirstSection() {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let ctx1 = gsap.context(() => {
            const tl1 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".box",
                    start: "-=80 40%",
                    end: "-5%",
                    pin: true,
                    scrub: 0.2,
                    markers: false,
                    toggleActions: "play reverse play reverse",
                }

            });

            tl1
                .to('.box', { opacity: 1, duration: 1 }, -1)
                .to('.box', { opacity: 0, duration: 1 }, 1)

        });
        return () => ctx1.revert();

    }, [])

    useEffect(() => {
        let ctx2 = gsap.context(() => {
            const tl2 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".box2",
                    start: "-=80 40%",
                    end: "-5%",
                    pin: false,
                    scrub: 0.2,
                    markers: false,
                    toggleActions: "play reverse play reverse",
                }

            });

            tl2
                .to('.box2', { opacity: 1, duration: 1 }, -1)
                .to('.box2', { opacity: 0, duration: 1 }, 1)

        });
        return () => ctx2.revert();

    }, [])
    return (
        <>
            <div>
                <FixedContainer className={'box2'}>
                    <Title >Consulting</Title>
                </FixedContainer>
                
                <Row className='g-4 justify-content-center'>
                    <Col xs={12} sm={8} md={6} xl={4}>
                        <Cards
                            image={require(`../../assets/images/about/Aktive1.png`)}
                            title={'Business Analyse'}
                            maxWidth={'unset'}
                            aspectRatio={'unset'}
                            marginBottom={'0px'}
                            textAlign={'center'}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} xl={4} >
                        <Cards
                            image={require(`../../assets/images/about/Aktive2.png`)}

                            title={'Projektleitung'}
                            maxWidth={'unset'}
                            aspectRatio={'unset'}
                            marginBottom={'0px'}
                            textAlign={'center'}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} xl={4} >
                        <Cards
                            image={require(`../../assets/images/about/Aktive3.png`)}

                            title={'PMO'}
                            maxWidth={'unset'}
                            aspectRatio={'unset'}
                            marginBottom={'0px'}
                            textAlign={'center'}
                        />
                    </Col>
                    <Col xs={12} sm={8} md={6} xl={4} >
                        <Cards
                            image={require(`../../assets/images/about/Aktive4.png`)}

                            title={'Testing'}
                            maxWidth={'unset'}
                            aspectRatio={'unset'}
                            marginBottom={'0px'}
                            textAlign={'center'}
                        />
                    </Col>
                </Row>
            </div>

            <div>

                <div className='heightBoxWrapper'>
                    <div className="box hiddenTitle">
                        <Title marginBottom={'2rem'}>Projekte</Title>
                    </div>
                </div>
                <Row className='g-4 justify-content-center'>

                    {
                        ProjectData.projects.map((project) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={project.id}>
                                    <Cards
                                        image={require(`../../assets/images/about/${project.image}.png`)}
                                        title={project.title}
                                        subtitle={project.subtitle}
                                        label={project.label}
                                        maxWidth={'unset'}
                                        aspectRatio={'unset'}
                                    />
                                </Col>
                            )
                        })
                    }

                </Row>
            </div>
        </>

    )
}

export default FirstSection