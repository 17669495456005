import React from 'react'
import styled from 'styled-components'
import Bg from '../assets/svgs/background.svg'

const ImageWrapper = styled.div`
    background: url(${Bg}) no-repeat;
    height: 100vh;
    width: 100%;
    background-size: cover;
    background-position: center;
    position: fixed;
    z-index: -10;
`
function FullBgImage() {
    return (
        <ImageWrapper>

        </ImageWrapper>
    )
}

export default FullBgImage