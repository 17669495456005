import React from 'react'
import Title from '../../components/Title'
import styled from 'styled-components'

import SliderComponent from '../../components/SliderComponent'
import { useEffect } from 'react';

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';


const StackContainer = styled.div`
    text-align: center;
`


function SecondSection() {
    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let ctx1 = gsap.context(() => {
            const tl1 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".box",
                    start: "-=80 40%",
                    end: "-5%",
                    pin: true,
                    scrub: 0.2,
                    markers: false,
                    toggleActions: "play reverse play reverse",
                }

            });

            tl1
                .to('.box', { opacity: 1, duration: 1 }, -1)
                .to('.box', { opacity: 0, duration: 1 }, 1)

        });
        return () => ctx1.revert();

    }, [])


    return (
        <div id='projekte' >

            <div className='heightBoxWrapper'>
                <div className="box hiddenTitle">
                    <Title>
                        Projekte
                    </Title>
                </div>
            </div>
            <StackContainer id='hide'>
                <SliderComponent />
            </StackContainer>
        </div>
    )
}

export default SecondSection