import React from 'react'
import Title from '../../components/Title'
import Subtitle from '../../components/Subtitle'
import ContactForm from '../../components/ContactForm'

import { gsap } from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { useEffect } from 'react';

function HomeContact() {

    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {
        let ctx3 = gsap.context(() => {
            const tl3 = gsap.timeline({
                scrollTrigger: {
                    trigger: ".contactTitle",
                    start: "-=80 40%",
                    end: "-5%",
                    pin: true,
                    scrub: true,
                    markers: false,
                    toggleActions: "play reverse play reverse",
                }

            });

            tl3
                .to('.contactTitle', { opacity: 1, duration: 1 }, -1)
                .to('.contactTitle', { opacity: 0, duration: 1 }, 1)

        });
        return () => ctx3.revert();
    }, [])
    return (
        <>
            <div className='heightBoxWrapper'>
                <div className="contactTitle hiddenTitle">
                    <Title>
                        KONTAKTIERE UNS
                    </Title>
                    <Subtitle>
                        Melde dich jetzt bei uns für ein unverbindliches Erstgespräch.
                    </Subtitle>
                </div>
            </div>

            <div>
                <ContactForm />
            </div>
        </>
    )
}

export default HomeContact