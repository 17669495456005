import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../assets/svgs/logoBlack.svg'
import { Link } from 'react-router-dom'

const FooterText = styled.div`
    padding-bottom: 0.75rem;
    color: #2E2E32;
    font-weight: 500;
`
const SmallFooterText = styled.div`
    color: #2E2E32;
    font-weight: 500;
    font-size: 12px;
`
const LinksTitle = styled.div`
    color: rgba(0, 0, 0, 0.4);
    font-weight: 500;
    padding-bottom: 1rem;
`
const LogoContainer = styled.div`
    svg {
        width: 100%;
        max-width: 110px;
    }
`
function Footer() {
    return (
        <Container fluid={'lg'} className='px-4 sectionBreak pb-5' >
            <Row className='gx-5 gx-md-5 gy-5 justify-content-between'>
                <Col xs={12} md={4} lg={4} xl={4}>

                    <Row className='gy-0 gx-2 mb-3'>
                        <Col xs={'auto'} className='mt-auto'>
                            <SmallFooterText>
                                Design by
                            </SmallFooterText>
                        </Col>
                        <Col xs={'auto'}>
                            <LogoContainer>
                                <Logo />
                            </LogoContainer>
                        </Col>
                    </Row>
                    <SmallFooterText>

                        Copyright 2023
                    </SmallFooterText>

                </Col>
                <Col xs={'6'} sm={'3'} lg={'auto'} xl={'auto'}>
                    <LinksTitle>
                        Social
                    </LinksTitle>
                    <a href={'https://instagram.com/swissma.ch'} target="_blank" rel="noreferrer" className='text-decoration-none'>
                        <FooterText>
                            Instagram
                        </FooterText>
                    </a>

                    <FooterText>
                        Facebook
                    </FooterText>

                </Col>



                <Col xs={'6'} sm={'3'} lg={'auto'} xl={'auto'}>
                    <LinksTitle>
                        Rechtlichhes
                    </LinksTitle>

                    <Link to={'/impressum'} className='text-decoration-none'>
                        <FooterText>
                            Impressum
                        </FooterText>
                    </Link>

                    <Link to={'/datenschutz'} className='text-decoration-none'> 
                        <FooterText>
                            Datenschutz
                        </FooterText>
                    </Link>
                </Col>
                <Col xs={'6'} sm={'3'} lg={'auto'} xl={'auto'}>
                    <LinksTitle>
                        Swissma GmbH
                    </LinksTitle>
                    <Link to={'/uber-uns'} className='text-decoration-none'>
                        <FooterText>
                            Über uns
                        </FooterText>
                    </Link>
                    <Link to={'/projekte'} className='text-decoration-none'>
                        <FooterText>
                            Projekte
                        </FooterText>
                    </Link>
                    <a href={'/karriere'} className='text-decoration-none'>

                        {/* <FooterText>
                        Karriere
                    </FooterText> */}
                    </a>

                </Col>
            </Row>
        </Container>
    )
}

export default Footer