import React from 'react'
import Image1 from '../assets/images/about/aboutSlider.webp'
// import Image2 from '../assets/images/about/CompanySliderImg2.png'
import styled from 'styled-components'

const ImageContainer = styled.img`
    max-width: 700px;
    width: 100%;
    margin: auto;
`
// const CornerLabel = styled.div`
//     position: absolute;
//     top: 1rem;
//     left: 1rem;
//     color: #F5F5F5;
//     font-weight: 600;
//     font-size: 12px;
//     letter-spacing: 1px;
//     text-transform: uppercase;
//     background: #19191A;
//     border-radius: 9999px;
//     padding: 6px 6px;
//     line-height: 1;
// `


function CompanySlider() {
    return (
        <div style={{ textAlign: 'center' }}>
            <ImageContainer src={Image1} />
        </div>


    )
}

export default CompanySlider