import React, { useRef } from 'react'
import styled from 'styled-components'
import { ReactComponent as Logo } from '../assets/svgs/logo.svg'
import { Col, Row } from 'react-bootstrap'
import Button from '../components/Button'
import NavbarOpenContent from './NavbarOpenContent'
import { Link, useLocation } from 'react-router-dom'
import { useEffect } from 'react'
const NavbarContainer = styled.div`
  position: fixed;
  top: 1rem;
  width: 100%;
  padding-inline: 1.5rem;
  z-index: 100;
  .nav-open {
    height: calc(100vh - 10rem);
    overflow-y: auto; 
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .fixedWrapper {
    position: fixed;
    max-width: 500px;
    width: 100%;
  }
  @media (max-width: 550.98px) {
    .fixedWrapper {
      max-width: calc(100vw - 3rem);
    }
  }
  .nav-open button {
    background-color: transparent !important;
    color: #fff !important;
    font-weight: 400 !important;
  }

`
const NavbarContent = styled.div`
  border-radius: 27px;
  margin-inline: auto;
  background-color: #000;
  width: 100%;
  max-width: 500px;
  height: 56px;
  transition: height 0.2s linear;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`

const LogoContainer = styled.div`
  padding-inline: 20px;  
  transition: 0.3s background-color ease-in-out;
  
  svg {
    max-width: 110px;
  }
`

const ButtonContainer = styled.div`
  padding: 2px;
`

function Navbar() {
  const navRef = useRef()
  const btnRef = useRef()

  const ToggleNav = () => {
    if (btnRef.current.innerHTML === "Menu") {
      btnRef.current.innerHTML = "Zurück"
    }
    else {
      btnRef.current.innerHTML = "Menu"
    }

    navRef.current.classList.toggle('nav-open');
    
  }

  const location = useLocation()

  useEffect(() => {
    if (navRef.current.classList.contains('nav-open')) {
      ToggleNav()
    }
    
  }, [location])
  
  return (
    <NavbarContainer>
      <NavbarContent ref={navRef}>
        <div className='fixedWrapper'>

          <Row className='justify-content-between gx-0' style={{ background: '#000', borderRadius: '27px' }}>
            <Col xs={'auto'} className='my-auto pb-1'>
              <Link to='/' >
                <LogoContainer>
                  <Logo />
                </LogoContainer>
              </Link>
            </Col>
            <Col xs={'auto'}>
              <ButtonContainer>
                <Button onClick={ToggleNav}>
                  <div ref={btnRef}>
                    Menu
                  </div>
                </Button>
              </ButtonContainer>
            </Col>
          </Row>
        </div>
        <NavbarOpenContent />
      </NavbarContent>
    </NavbarContainer>
  )
}

export default Navbar