import React from 'react'
import AboutFirstSection from '../sections/About/AboutFirstSection'
import AboutSecondSection from '../sections/About/AboutSecondSection'
import AboutPortolio from '../sections/About/AboutPortolio'

function About() {
    return (
        <>
            <div className='container-lg px-4 firstSectionBreak'>
                <AboutFirstSection />
            </div>
            <div className='container-lg px-4'>
                <AboutSecondSection />
            </div>
            <div className='container-lg px-4 sectionBreak'>
                <AboutPortolio />
            </div>
        </>
    )
}

export default About