import React from 'react'
// import Title from '../../components/Title'
// import NewsSlider from '../../components/NewsSlider'
// import styled from 'styled-components'
// import { Link } from 'react-router-dom'

// const ShowAll = styled.button`
//     margin-inline: auto;
//     border: none;
//     margin-top: 3rem;
//     text-align: center;
//     padding: 10px 20px;
//     color: #fff;
//     background: rgba(0, 0, 0, 0.12);
//     border-radius: 9999px;
//     font-weight: 500;
//     cursor: pointer;
// `
function HomeNews() {
    return (
        <>
            {/* <div id='reveal3' >
                <Title>
                    News
                </Title>
            </div>
            <NewsSlider />
            <div style={{ textAlign: 'center' }}>
                <a href={'/news'} className='text-decoration-none'>
                    <ShowAll>
                        Alle Artikel
                    </ShowAll>
                </Link>
            </div> */}
        </>
    )
}

export default HomeNews