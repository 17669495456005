import React from 'react'
import styled from 'styled-components'


const TitleContainer = styled.div`
    font-weight: 700;
    line-height: 135.4%;
    font-size: 100px;
    color: #2E2E32;
    text-align: center;
    letter-spacing: -0.02em;
    text-transform: uppercase;
    
    
    @media (max-width: 1399.98px) {
  
    }

    @media (max-width: 1199.98px) {
        font-size: 90px;
    }


    @media (max-width: 991.98px) {
        font-size: 75px;
    }

    @media (max-width: 767.98px) {
        font-size: 55px;

    }

    @media (max-width: 575.98px) {
        line-height: 1.5;
        font-size: 35px;
    }
    @media (max-width: 350.98px) {
        line-height: 1.5;
        font-size: 35px;
    }
`
function Title({ children, marginBottom }) {

    return (
        <TitleContainer style={{ marginBottom: marginBottom }}>
            {children}
        </TitleContainer>
    )
}

export default Title