import React, { useRef, useState, useEffect } from 'react'
import CategoriesSwiper from '../../components/CategoriesSwiper'
import Cards from '../../components/Cards'

import KutizaLogo from '../../assets/svgs/kutizaLogo.svg'

import { Col, Row } from 'react-bootstrap'
import StaffCards from '../../components/StaffCards'

import Data from '../../assets/data/about/StaffData.json'
import Categories from '../../assets/data/about/Categories.json'
import { ClipLoader } from 'react-spinners';

function AboutPortolio() {

    const [active, setActive] = useState(0)

    const [loading, setLoading] = useState(true);

    const instancesCount = useRef(0)
    const counter = useRef(0)

    const ImageLoaded = () => {

        counter.current += 1
        if (counter.current >= instancesCount.current) {
            setLoading(false)
        }

    }

    useEffect(() => {
        if (active !== 5) {
            setLoading(true)

        }

    }, [active])

    return (
        <>
            <CategoriesSwiper
                active={active}
                setActive={setActive}
                data={Categories}
            />

            {loading && (
                <div className='text-center'>
                    <ClipLoader
                        color="#dadada"
                        size={80}
                    />
                </div>
            )}

            <Row className={`g-4 justify-content-center ${loading && 'd-none'}`}>
                {
                    {
                        0: Data.managment.map((staff) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={staff.id} className="d-flex">
                                    <StaffCards
                                        image={require(`../../assets/images/about/${staff.image}.webp`)}
                                        title={staff.title}
                                        label={staff.label}
                                        instancesCount={instancesCount}
                                        ImageLoaded={ImageLoaded}
                                    />
                                </Col>
                            )
                        }),

                        1: Data.berater.map((staff) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={staff.id} className="d-flex">
                                    <StaffCards
                                        image={require(`../../assets/images/about/Berater/${staff.image}.svg`)}
                                        title={staff.title}
                                        label={staff.label}
                                        instancesCount={instancesCount}
                                        ImageLoaded={ImageLoaded}

                                    />
                                </Col>
                            )
                        }),

                        2: Data.kutizaManagment.map((staff) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={staff.id} className="d-flex">
                                    <StaffCards
                                        image={require(`../../assets/images/about/${staff.image}.webp`)}
                                        // title={staff.title}
                                        label={staff.label}
                                        instancesCount={instancesCount}
                                        ImageLoaded={ImageLoaded}
                                        noImage={true}
                                    />
                                </Col>
                            )
                        }),

                        3: Data.informatik.map((staff) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={staff.id} className="d-flex">
                                    <StaffCards
                                        image={require(`../../assets/images/about/Informatik/${staff.image}.webp`)}
                                        // title={staff.title}
                                        label={staff.label + ' (Kutiza)'}
                                        instancesCount={instancesCount}
                                        ImageLoaded={ImageLoaded}
                                        noImage={true}

                                    />

                                </Col>
                            )
                        }),

                        4: Data.marketing.map((staff) => {
                            return (
                                <Col xs={12} sm={8} md={6} xl={4} key={staff.id} className="d-flex">
                                    <StaffCards
                                        image={require(`../../assets/images/about/Marketing/${staff.image}.webp`)}
                                        // title={staff.title}
                                        label={staff.label + ' (Kutiza)'}

                                        instancesCount={instancesCount}
                                        ImageLoaded={ImageLoaded}
                                        noImage={true}

                                    />
                                </Col>
                            )
                        }),

                        5:
                            <>
                                <Col xs={12} sm={8} md={6} xl={4}>
                                    <Cards
                                        image={KutizaLogo}
                                        title={'Kutiza'}
                                        label={'Near Sourcing'}
                                        subtitle={`Kutiza ist eine Full-Service-Digitalagentur, welche Exklusiv für die Swissma GmbH arbeitet. Ihr Serviceangebot reicht von hochwertiger Webentwicklung, E-Commerce-Lösungen und mobiler App-Entwicklung bis hin zu fortschrittlicher Suchmaschinenoptimierung (SEO) und UI/UX Optimierungen.`}
                                        maxWidth={'unset'}
                                        aspectRatio={'unset'}
                                    />
                                </Col>
                                {/* <Col xs={12} sm={8} md={6} xl={4}>
                                    <Cards
                                        image={InsuplusLogo}
                                        title={'Insuplus'}
                                        label={'CRM'}
                                        subtitle={`Insuplus is a leading telecommunications agency that has carved a unique niche in the dynamic intersection of telecommunications and the insurance industry. 
                                        
                                        They bring to the table a deep understanding of the insurance industry, coupled with unparalleled expertise in telecommunications, creating a powerful synergy that truly sets them apart.
                                        
                                        Insuplus ist eine führende Telekommunikationsagentur, die sich eine einzigartige Nische im dynamischen Schnittpunkt von Telekommunikation und Versicherungswirtschaft erarbeitet hat.`}
                                        buttonTitle={'Watch'}
                                        maxWidth={'unset'}
                                        aspectRatio={'unset'}
                                    />
                                </Col> */}
                            </>
                    }[active]
                }

            </Row >

        </>
    )
}

export default AboutPortolio